import React, {useContext, useEffect, useState} from 'react';
import {Col, DatePicker, Input, message, Modal, Row, Select, Tooltip} from "antd";
import moment from "moment/moment";
import TextArea from "antd/lib/input/TextArea";
import {QuestionCircleOutlined} from "@ant-design/icons";
import UserProfileContext from "../../../../services/UserProfileContext";
import {apiClient} from "../../../../services/ApiClient";

const { Option } = Select;
const { RangePicker } = DatePicker;
const rowStyle = {padding: '8px'};

export default function CarReturnLimitEdit({record, onOk, onCancel}) {

  let userProfile = useContext(UserProfileContext);

  const [editedRecord, setEditedRecord] = useState({})
  let [regions, setRegions] = useState([]);

  useEffect(() => {
    setEditedRecord(record)
  }, [record]);

  useEffect(() => {
    console.log("editedRecord", editedRecord);
  }, [editedRecord]);

  useEffect(() => {
    apiClient.getRegions({"order": "title", "skip_default": true})
      .then(res => {
        setRegions(res.records);
      })
      .catch(err => { console.error(err) });
  }, [])

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="size-for-middle-window"
        style={{top: 10}}
        title={"Изменить запись"}
        visible={true}
        onOk={() => {
          if(!editedRecord.from_dt) {
            message.error("Укажите дату начала периода");
            return;
          }
          if(!editedRecord.to_dt) {
            message.error("Укажите дату окончания периода");
            return;
          }
          if(!editedRecord.limit) {
            message.error("Укажите лимит");
            return;
          }
          onOk({
            ...editedRecord,
          });
        }}
        onCancel={onCancel}
      >
        <>
          <h4>Настройки для страницы: <a target={"_blank"} href={`${process.env.REACT_APP_WEB_HOST}/vozvrat/${userProfile.tenant.code}`}>{process.env.REACT_APP_WEB_HOST}/vozvrat/{userProfile.tenant.code}</a></h4>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>
            <Tooltip
              placement="top"
              title="Выберите период, в который будет установлено ограничение по возврату ТС в день"
            >
              Период <QuestionCircleOutlined />
            </Tooltip>
          </Col>
          <Col span={18}>
            <RangePicker
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              disabledDate={(dt) => {
                return dt && dt.startOf('day') < moment().startOf('day');
              }}
              value={editedRecord.from_dt && editedRecord.to_dt ? [moment(editedRecord.from_dt), moment(editedRecord.to_dt)] : []}
              onChange={(dates, dateStrings) => {
                if(dates) {
                  setEditedRecord({...editedRecord, ...{
                    from_dt: dates[0].format("YYYY-MM-DD"),
                    to_dt: dates[1].format("YYYY-MM-DD"),
                  }})
                } else {
                  setEditedRecord({...editedRecord, ...{
                      from_dt: null,
                      to_dt: null,
                    }}
                  )
                }
              }}
            />
          </Col>
        </Row>

        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>
            <Tooltip
              placement="top"
              title="Выберите филиалы, для готорых установлено ограничение по возврату ТС в день"
            >
              Филиалы <QuestionCircleOutlined />
            </Tooltip>
          </Col>
          <Col span={18}>
            <Select
              showSearch
              allowClear
              loading={!regions}
              mode={"multiple"}
              placeholder="Филиал"
              value={regions?.length > 0 ? editedRecord?.regions : []}
              style={{ width: '100%' }}
              onChange={(data) => { setEditedRecord(prevState => { return {...prevState, regions: data} }); }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
          </Col>
        </Row>

        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>
            <Tooltip
              placement="top"
              title="Укажите максимальный лимит количества ТС в день для возврата в таксопарк"
            >
              Лимит в день <QuestionCircleOutlined />
            </Tooltip>
          </Col>
          <Col span={18}>
            <Input allowClear={true}
                   value={editedRecord.limit}
                   onChange={(e) => { setEditedRecord({...editedRecord, limit: e.target.value}) }}
                   placeholder="Лимит"
                   style={{width: 120}}
                   type={"number"}
            />
          </Col>
        </Row>

        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Коммемнтарий</Col>
          <Col span={18}>
            <TextArea allowClear={true}
                   value={editedRecord.comment}
                   onChange={(e) => { setEditedRecord({...editedRecord, comment: e.target.value}) }}
            />
          </Col>
        </Row>
        </>
      </Modal>
    </>
  )
}