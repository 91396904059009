import React, {useEffect, useState} from 'react';
import {apiClient} from "../../../../services/ApiClient";
import {Button, Col, Input, message, Modal, Row, Space, Spin, Table, Tabs} from "antd";
import {EditFilled} from "@ant-design/icons";
import NotificationPeriodEditDialog from "../../settings/components/notification-period/NotificationPeriodEditDialog";

const { TabPane } = Tabs;
const PROP_ALERT_PERIOD = "driversmanager.kasko.alert_period_days";

export default function ServiceKaskoStatusSettingsDialog({onClose}) {

  let [loading, setLoading] = useState(false);
  let [notificationsPeriodBusy, setNotificationsPeriodBusy] = useState(false);
  let [alertsPeriod, setAlertsPeriod] = useState("");
  let [notificationPeriods, setNotificationPeriods] = useState([]);
  let [notificationPeriodsUpdate, setNotificationPeriodsUpdate] = useState(0);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient.getSystemProperty(PROP_ALERT_PERIOD)
      .then(res => {
        setAlertsPeriod(res.value);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); })
  }, [])

  useEffect(() => {
    apiClient.getKaskoNotificationPeriods({})
      .then(res => {
        setNotificationPeriods(res.records);
      })
      .catch(err => message.error(err))
  }, [notificationPeriodsUpdate]);

  const updateNotificationPeriod = (id, params) => {
    setNotificationsPeriodBusy(true);
    apiClient.updateKaskoNotificationPeriod(id, params)
      .then(res => {
        setNotificationPeriodsUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err))
      .finally(() => { setNotificationsPeriodBusy(false); })
  };

  const createNotificationPeriod = params => {
    setNotificationsPeriodBusy(true);
    apiClient.createKaskoNotificationPeriod(params)
      .then(res => {
        setNotificationPeriodsUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err))
      .finally(() => { setNotificationsPeriodBusy(false); })
  };

  const deleteNotificationPeriods = () => {
    setNotificationsPeriodBusy(true);
    Promise.all(selectedRows.map(row => apiClient.deleteKaskoNotificationPeriod(row.id)))
      .then(res => {
        message.success("Записи удалены");
        setSelectedRows([]);
        setNotificationPeriodsUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err))
      .finally(() => setNotificationsPeriodBusy(false));
  }

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      width={800}
      title={"Настройки"}
      visible={true}
      mask={loading}
      onOk={() => {
        let props = {}
        props[PROP_ALERT_PERIOD] = alertsPeriod;
        apiClient.setSystemProperties(props)
          .then(res => {
            message.info("Изменения сохранены");
            onClose();
          })
          .catch(err => { message.error(err); })
      }}
      onCancel={onClose}>

      <div>
        <div hidden={!loading} style={{textAlign: 'center'}}>
          <Spin />
        </div>
        <div hidden={loading}>
          <>
            <Tabs>
              <TabPane tab="Период фильтра" key="1">
                <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
                  <Col span={8}>
                    <h4>Период оповещения об оплате</h4>
                  </Col>
                  <Col span={16}>
                    <Input
                      type="number"
                      allowClear={true}
                      style={{marginBottom: 8}}
                      value={alertsPeriod}
                      onChange={(e) => { setAlertsPeriod(e.target.value) }}/>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Периоды уведомлений" key="2">
                <Space style={{marginBottom: 10}}>
                  <Button disabled={loading || notificationsPeriodBusy} onClick={() => { setEditedRecord({id: 0}) }} type={"primary"}>Добавить</Button>
                  <Button disabled={loading || notificationsPeriodBusy || selectedRows.length < 1} onClick={() => { deleteNotificationPeriods() }} type={"danger"}>Удалить</Button>
                </Space>
                <Table
                  loading={loading || notificationsPeriodBusy}
                  size={'small'}
                  sticky
                  columns={[
                    {
                      title: "",
                      align: "center",
                      width: 10,
                    },
                    Table.SELECTION_COLUMN,
                    {
                      title: '',
                      width: 50,
                      render: (text, record, index) => {
                        return (
                          <Space direction={"horizontal"}>
                            <Button
                              type="link"
                              icon={<EditFilled/>}
                              disabled={!record?.id}
                              onClick={() => {
                                setEditedRecord(record)
                              }}
                            />
                          </Space>
                        )
                      }
                    },
                    {
                      title: 'Период, дней',
                      render: (text, record, index) => {
                        return record.period;
                      }
                    },
                    {
                      title: 'Уведомление',
                      render: (text, record, index) => {
                        return record.message;
                      }
                    },
                    {
                      title: "",
                      align: "center",
                      width: 10,
                    }
                  ]}
                  dataSource={notificationPeriods}
                  rowKey="id"
                  rowSelection={{
                    onChange: (selectedRowKeys, selectedRows) => {
                      setSelectedRows(selectedRows);
                    },
                    selectedRowKeys: selectedRows.map(el => el.id)
                  }}
                />
              </TabPane>
            </Tabs>
          </>
        </div>
        {
          editedRecord !== null && (
            <NotificationPeriodEditDialog
              title={"Уведомления: КАСКО"}
              record={editedRecord}
              onOK={(record) => {
                if(record.id) {
                  updateNotificationPeriod(record.id, record)
                } else {
                  createNotificationPeriod(record)
                }
                setEditedRecord(null);
              }}
              onCancel={() => { setEditedRecord(null) }}
            />
          )
        }
      </div>
    </Modal>
  );
}