import React, {useEffect, useState} from "react";
import {DatePicker, message, PageHeader, Select, Space, Table, Input} from "antd";
import moment from "moment";
import {LoadingOutlined} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import {DriverTransactionCategories} from "../../common/AppEnums";
import UpdateButton from "../../buttons/UpdateButton";

const { Option } = Select;
const { RangePicker } = DatePicker;
const {Search} = Input;

export default function DriverTransactionsPage() {
  let [records, setRecords] = useState([]);
  let [loading, setLoading] = useState(false);
  let [recordsStamp, setRecordsStamp] = useState(0);
  let [transactionsFilter, setTransactionsFilter] = useState({type: '', period: [moment(), moment()]});
  let [drivers, setDrivers] = useState([]);
  let [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    position: 'topRight',
    defaultPageSize: 50,
    pageSizeOptions: [25, 50, 75]
  });
  let [sorter, setSorter] = useState({});

  useEffect(() => {
    apiClient.searchDirectoryRecord('taxiDriver', '')
      .then(res => {
        setDrivers(res.records);
      })
      .catch(err => {
        message.error(err);
      })
  }, []);

  useEffect(() => {
    setLoading(true);
    let filter = {
      type: transactionsFilter.type,
      category: transactionsFilter.category,
      vehicleNumber: transactionsFilter.vehicleNumber,
      driver: transactionsFilter.driver,
      ...{
        page: pagination.current,
        pageSize: pagination.pageSize
      },
      ...{
        sortField: sorter.field,
        sortOrder: sorter.order
      }
    };
    if(transactionsFilter.period && transactionsFilter.period.length > 0) {
      filter.from = moment(transactionsFilter.period[0]).format('YYYY-MM-DD');
      filter.to = moment(transactionsFilter.period[1]).format('YYYY-MM-DD');
    }
    apiClient.getDriverTransactions(filter)
      .then(res => {
        setRecords(res.records);
        setPagination(prevState => {return {...prevState, total: res.total}})
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [recordsStamp, transactionsFilter]);

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: 'Дата',
      dataIndex: 'event_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.event_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: "Операция",
      dataIndex: "operation_type",
      render: (text, record, index) => {
        if(record.operation_type === "debit") {
          return "Списание средств";
        }
        if(record.operation_type === "credit") {
          return "Зачисление средств";
        }
        return record.operation_type;
      },
    },
    {
      title: "Водитель",
      dataIndex: "driver_details",
      render: (text, record, index) => {
        if(record.driver_details) {
          return record.driver_details.name;
        }
        return "";
      },
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      align: "center",
    },
    {
      title: "Марка/Модель",
      align: "center",
      render: (text, record, index) => {
        return record.driver_rent_details?.brand_model;
      },
    },
    {
      title: "Рег.номер",
      dataIndex: "driver_rent_details.reg_number",
      align: "center",
      render: (text, record, index) => {
        return record.driver_rent_details?.reg_number;
      },
    },
    {
      title: "Состояние",
      dataIndex: "processing_status",
      align: "center",
      render: (text, record, index) => {
        if(record.processing_status === "new" || record.processing_status === "processing") {
          return <LoadingOutlined />;
        }
        if(record.processing_status === "processed") {
          return "Обработка сервисом";
        }
        if(record.processing_status === "complete") {
          return "Обработана";
        }
        if(record.processing_status === "failed") {
          return "Ошибка";
        }
      },
    },
    {
      title: "Категория",
      dataIndex: "category_name",
    },
    {
      title: "Комментарий",
      dataIndex: "comment"
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Транзакции"
      />
      <div style={{width: '100%'}}>
          <div style={{width: '80%', float: 'left', marginBottom: 40}}>
            <Space direction={"horizontal"} wrap>
              <Select bordered allowClear
                placeholder="Тип операции"
                onChange={(e) => { setTransactionsFilter({...transactionsFilter, ...{type: e}}) }}
                style={{width: '200px'}}
              >
                <Option key='debit' value='debit'>Списание</Option>
                <Option key='credit' value='credit'>Пополнение</Option>
              </Select>
              <Select
                bordered
                showSearch
                allowClear
                placeholder="Категория"
                onChange={(e) => { setTransactionsFilter({...transactionsFilter, ...{category: e}}) }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                style={{width: '300px'}}>
                {
                  DriverTransactionCategories
                    .filter(e => !e.hidden)
                    .map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
                }
              </Select>
              <Select
                showSearch
                allowClear
                style={{ width: 350 }}
                placeholder="Водитель"
                onChange={(value) => { setTransactionsFilter({...transactionsFilter, ...{driver: value}}) }}
                filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {drivers.map(s => <Option key={s.id} value={s.id}>{`${s.last_name} ${s.first_name} ${s.middle_name} (${s.phone})`}</Option>)}
              </Select>
              <Search
                enterButton
                placeholder={"Поиск по гос.номеру"}
                onSearch={(value) => { setTransactionsFilter({...transactionsFilter, ...{vehicleNumber: value}}) }}
                allowClear={true}
                style={{width: 300}}
              />
              <RangePicker format="DD.MM.YYYY" value={transactionsFilter.period} style={{width: 350}} onChange={(val) => setTransactionsFilter({...transactionsFilter, ...{period: val}})}/>
            </Space>
          </div>
          <div style={{width: '20%', float: 'right'}}>
          <Space style={{float: "right"}}>
            <UpdateButton onClick={ () => setRecordsStamp(prevState => prevState + 1) }/>
          </Space>
          </div>
      </div>

      <Table dataSource={records}
             columns={columns}
             size="small"
             style={{margin: '10px 0', marginTop: 40}}
             rowKey="id"
             loading={loading}
             pagination={pagination}
             scroll={{x: 1500}}
             sticky
             onChange={(pagination, filters, sorter) => {
               setPagination(pagination);
               setSorter(sorter);
               setRecordsStamp(prevState => prevState + 1)
             }}
      />
    </>
  );
}