import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  PageHeader,
  Select,
  Space, Switch,
  Table, Tooltip,
} from "antd";
import {DownOutlined, QuestionCircleOutlined, SettingOutlined} from "@ant-design/icons";
import moment from "moment";
import {windowService} from "../../../../services/WindowService";
import ServiceOsgopStatusSettingsDialog from "./ServiceOsgopStatusSettingsDialog";

const { Search } = Input;
const { Option } = Select;

const FILTER_DUE_ALERT = "dueAlert";

export default function ServiceOsgopStatusPage({globalUpdate, alertsCount, notifyGlobalUpdate}) {
  let [update, setUpdate] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [search, setSearch] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [selectedRecordsOperation, setSelectedRecordsOperation] = useState(null);
  let [owners, setOwners] = useState([]);
  let [selectedOwnerId, setSelectedOwnerId] = useState([]);
  let [ownersLoading, setOwnersLoading] = useState(false);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState([]);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState([]);
  let [customFilter, setCustomFilter] = useState(null);
  let [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiClient.getServiceOsgopStatusList({ regNumber: search, ownerId: selectedOwnerId, regionId: selectedRegionId, groupId: selectedGroupId, customFilter: customFilter })
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  }, [globalUpdate, update, search, selectedOwnerId, selectedRegionId, selectedGroupId, customFilter]);

  useEffect(() => {
    setOwnersLoading(true);
    apiClient.getVehicleOwners({"view": "minimal"})
      .then(res => {
        setOwners(res.records);
      })
      .catch(err => { message.error(err) })
      .finally(() => { setOwnersLoading(false);});

    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { message.error(err) });

    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { message.error(err) });
  }, [globalUpdate]);

  const executeSelectedRecordsOperation = operationKey => {
    setSelectedRecordsOperation({"key": operationKey});
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="ОСГОП"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              {
                alertsCount > 0
                  ? (
                    <>
                      <Switch
                        checkedChildren={<span>Со сроком оплаты: {alertsCount}</span>}
                        unCheckedChildren={<span>Со сроком оплаты: {alertsCount}</span>}
                        checked={customFilter === FILTER_DUE_ALERT}
                        onChange={(e) => {
                          e ? setCustomFilter(FILTER_DUE_ALERT) : setCustomFilter(null);
                          setSelectedRecords([]);
                        }}
                      />
                      <Tooltip
                        placement="top"
                        title="При выборе этого фильтра Вы увидите ТС, у которых приближается срок оплаты полиса"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  ) : null
              }
              <span>{`Количество записей: ${records.length}`}</span>
              {
                selectedRecords.length > 0 && false
                  ? (
                    <>
                      <Dropdown
                        disabled={selectedRecords.length < 1}
                        overlay={(
                          <Menu onClick={(e) => { executeSelectedRecordsOperation(e.key); }}>

                          </Menu>
                        )}>
                        <Button style={{textAlign: "left"}}>
                          С выбранными <DownOutlined/>
                        </Button>
                      </Dropdown>
                    </>
                  ) : null
              }
              <Button type={"link"} icon={<SettingOutlined/>} style={{}}
                      onClick={() => {
                        setShowSettings(true);
                      }}>
                Настройки
              </Button>
            </Space>
          </div>
        }
      />

      <Space style={{margin: '10px 0'}} direction="horizontal" align={{width: '100%'}}>
        <Search
          enterButton
          placeholder={"Поиск по гос.номеру"}
          onSearch={(value) => {
            setSearch(value)
          }}
          allowClear={true}
          style={{width: 400}}
        />
        <Select
          allowClear
          showSearch
          loading={ownersLoading}
          placeholder="Собственник"
          value={selectedOwnerId}
          style={{width: 300}}
          onChange={(data) => { setSelectedOwnerId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            owners.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          mode="multiple"
          placeholder="Филиал"
          value={selectedRegionId}
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          placeholder="Группа"
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1500}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          Table.SELECTION_COLUMN,
          // {
          //   title: '',
          //   width: 50,
          //   render: (text, record, index) => {
          //     return (
          //       <Space direction={"horizontal"}>
          //         <Button
          //           type="link"
          //           icon={<EditFilled/>}
          //           disabled={!record?.id}
          //           onClick={() => {
          //             setSelected(record);
          //           }}
          //         />
          //       </Space>
          //     )
          //   }
          // },
          {
            title: 'Номер ТС',
            width: 150,
            align: "center",
            render: (text, record, index) => {
              console.log({record});
              if(!record.car) {
                return "";
              }
              return (
                <Button
                  type={"link"}
                  onClick={() => {
                    windowService.openRouteWindow(`cp/park/vehicles/${record.car.id}`);
                  }}
                >
                  {
                    <>
                      <div>
                        {record.car.reg_number}
                      </div>
                    </>
                  }
                </Button>
              );
            }
          },
          {
            title: 'Марка/Модель',
            align: "center",
            render: (text, record, index) => {
              return `${record.car?.brand} ${record.car?.model}`;
            }
          },
          {
            title: 'Страховщик',
            align: "center",
            render: (text, record, index) => {
              return `${record.company_title}`;
            }
          },
          {
            title: '№ полиса',
            align: "center",
            render: (text, record, index) => {
              return `${record.policy_number}`;
            }
          },
          {
            title: 'Дата полиса',
            align: "center",
            dataIndex: 'policy_issue_dt',
            render: (text, record, index) => {
              return record.policy_issue_dt ? moment(record.policy_issue_dt).format('DD.MM.YYYY'): "";
            },
            sorter: (a, b) => {
              let aVal = a.policy_issue_dt ? moment(a.policy_issue_dt).valueOf() : -1;
              let bVal = b.policy_issue_dt ? moment(b.policy_issue_dt).valueOf() : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Дата окончания полиса',
            align: "center",
            dataIndex: 'policy_due_dt',
            render: (text, record, index) => {
              return record.policy_due_dt ? moment(record.policy_due_dt).format('DD.MM.YYYY'): "";
            },
            sorter: (a, b) => {
              let aVal = a.policy_due_dt ? moment(a.policy_due_dt).valueOf() : -1;
              let bVal = b.policy_due_dt ? moment(b.policy_due_dt).valueOf() : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'ФИО собственника',
            render: (text, record, index) => {
              return record.owner?.title;
            },
            sorter: (a, b) => {
              let aVal = a.owner?.title || "";
              let bVal = b.owner?.title || "";
              return aVal.localeCompare(bVal);
            },
          },
          {
            title: 'Кол-во дней до окончания полиса',
            align: "center",
            render: (text, record, index) => {
              return record.policy_due_dt ? moment(record.policy_due_dt)
                  .endOf('day')
                  .diff(moment(), 'days') + 1
                : "";
            },
            sorter: (a, b) => {
              let aVal = a.policy_due_dt ? moment(a.policy_due_dt).diff(moment(), 'days') : -1;
              let bVal = b.policy_due_dt ? moment(b.policy_due_dt).diff(moment(), 'days') : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Город',
            render: (text, record, index) => {
              return record.region?.title;
            }
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75], hideOnSinglePage: true}}
        rowSelection={{
          columnWidth: '50px',
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRecords,
          onChange: (selectedRowKeys) => {
            setSelectedRecords(selectedRowKeys);
          }
        }}
      />
      {
        showSettings && (
          <ServiceOsgopStatusSettingsDialog
            onClose={() => {
              setShowSettings(false);
              notifyGlobalUpdate();
            }}
          />
        )
      }
    </>
  )

}