import React, {useEffect, useState} from 'react';
import {Col, Input, Modal, Row} from "antd";

const { TextArea } = Input;

export default function NotificationPeriodEditDialog({title, record, onOK, onCancel}) {

  const [data, setData] = useState(null)

  useEffect(() => {
    setData(record);
  }, [record]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      width={800}
      title={title}
      visible={true}
      onOk={() => {
        onOK(data);
      }}
      onCancel={onCancel}
    >
      <div>
        <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
          <Col span={8}>
            <h4>Период (дней)</h4>
          </Col>
          <Col span={16}>
            <Input
              type="number"
              style={{marginBottom: 8}}
              value={data?.period}
              onChange={(e) => { setData({ ...data, period: e.target.value }) }}/>
          </Col>
        </Row>
        <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
          <Col span={8}>
            <h4>Сообщение</h4>
          </Col>
          <Col span={16}>
            <TextArea
              rows={5}
              allowClear={true}
              value={data?.message}
              onChange={(e) => {setData({ ...data, message: e.target.value });}}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  )
}