import React, {useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Modal, Radio,
  Row,
  Select,
  Space,
  Table,
  TimePicker, Tooltip
} from "antd";
import {DeleteOutlined, LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import {
  TgNotificationActionTypes,
  TgNotificationCompanyTypeList,
  TgNotificationCompanyTypePersonal,
  TgNotificationCompanyTypes
} from "../../../../common/AppEnums";
import {apiClient} from "../../../../../services/ApiClient";
import DriversPickerDialog from "../../../drivermanager/DriversPickerDialog";

const { Option } = Select;
const { TextArea } = Input;

const rowStyle = {padding: '4px'};

export default function NotificationCampaingEdit({campaign, visible, onOk, onCancel}) {

  let [loading, setLoading] = useState(false);
  let [record, setRecord] = useState({});
  let [recipients, setRecipients] = useState([]);
  let [pickupRecipients, setPickupRecipients] = useState(false);
  let [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    setRecord({...campaign});
    if(campaign && campaign.id > 0) {
      setLoading(true);
      apiClient.getNotificationCampaingRecipients(campaign.id)
        .then(res => {
          setRecipients(res.records.map(el => el.driver));
        })
        .catch(err => {
          console.error(err);
          message.warning(err)
        })
        .finally(() => { setLoading(false); })
    } else {
      setRecipients([]);
    }
  }, [campaign, visible]);

  useEffect(() => {
    console.log("record", record);
  }, [record]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={"Рассылка"}
      visible={visible}
      onOk={() => {
        if(!record.title) {
          message.info("Укажите наименование");
          return;
        }
        if(!record.bot_type) {
          message.info("Укажите типа рассылки");
          return;
        }
        if(record.company_type === TgNotificationCompanyTypeList && !recipients) {
          message.info("Необходимо указать адресатов рассылки");
          return;
        }
        if(record.company_type === TgNotificationCompanyTypePersonal && !record.chat_id) {
          message.info("Для персональной рассылки нужно указать ID адресата");
          return;
        }
        if(!record.message) {
          message.info("Укажите текст сообщения");
          return;
        }
        if(!record.start_dt) {
          message.info("Укажите дату начала рассылки");
          return;
        }
        if(moment(record.end_dt) < moment(record.start_dt)) {
          message.info("Дата начала рассылки должна быть раньше даты окончания");
          return;
        }
        if(!record.send_time || record.send_time.length < 1) {
          message.info("Необходимо указать не менее одного времени рассылки");
          return;
        }
        onOk(record, recipients.map(el => el.id));
      }}
      onCancel={() => {
        onCancel();
      }}>
      {
        loading ? (
          <div style={{margin: '20px auto', width: '100%', textAlign: 'center', fontSize: '2em'}}>
            <LoadingOutlined/>
          </div>
        ) : (
          <>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>
                <Space direction="horizontal">
                  <span>Наименование</span>
                  <Tooltip
                    placement="top"
                    title={"Укажите наименование рассылки (отображается только в CRM)"}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>

              </Col>
              <Col span={16}>
                <Input
                  placeholder={"Наименование"}
                  value={record?.title}
                  onChange={(e) => { setRecord({...record, title: e.target.value} ) }}
                  allowClear={true}
                  width={"100%"}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Тип рассылки</Col>
              <Col span={16}>
                <Select
                  style={{ width: '100%' }}
                  value={record?.bot_type}
                  onChange={(value) => {setRecord({...record, bot_type: value})}}
                >
                  <Option key={"DRIVER_BOT"} value={"DRIVER_BOT"}>{"Бот Водитель"}</Option>
                  <Option key={"MECHANIC_BOT"} value={"MECHANIC_BOT"}>{"Бот Механик"}</Option>
                  <Option key={"NOTIFICATION_BOT"} value={"NOTIFICATION_BOT"}>{"Бот уведомлений"}</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Отключено</Col>
              <Col span={16}>
                <Checkbox
                  checked={record?.is_disabled}
                  onChange={(e) => {
                    setRecord({...record, is_disabled: e.target.checked });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Групповой</Col>
              <Col span={16}>
                <Radio.Group
                  value={record?.company_type}
                  onChange={(e) => {
                    setRecord({...record, company_type: e.target.value });
                  }}>
                  {
                    TgNotificationCompanyTypes.map(el => (
                      <Radio.Button value={el.id}>{el.title}</Radio.Button>
                    ))
                  }
                </Radio.Group>
              </Col>
            </Row>
            {
              record?.company_type === TgNotificationCompanyTypePersonal && (
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={5}>ID адресата</Col>
                    <Col span={16}>
                      <Input
                        placeholder={"Telegram ID"}
                        value={record?.chat_id}
                        onChange={(e) => { setRecord({...record, chat_id: e.target.value} ) }}
                        allowClear={true}
                        width={"100%"}/>
                    </Col>
                  </Row>
                )
            }
            {
              record?.company_type === TgNotificationCompanyTypeList && (
                <Row gutter={[0, 16]} style={rowStyle}>
                  <Col span={5}>Адресаты</Col>
                  <Col span={16}>
                    <>
                      <Space direction={"horizontal"} style={{marginBottom: 10}} align={"right"}>
                        <Button
                          type="primary"
                          onClick={() => {
                            setPickupRecipients(true);
                          }}
                        >
                          Добавить
                        </Button>
                      </Space>
                      <Table
                        dataSource={recipients}
                        columns={[
                          {
                            title: "",
                            align: "center",
                            width: 5,
                          },
                          {
                            title: "",
                            width: 50,
                            align: "center",
                            dataIndex: "id",
                            render: function(text, item, index) {
                              return (
                                <Space direction={"horizontal"}>
                                  <Button type="link" icon={<DeleteOutlined/>} onClick={() => {
                                    setRecipients(prevState => {
                                      return prevState.filter(el => el.id !== item.id);
                                    })
                                  }}/>
                                </Space>
                              );
                            }
                          },
                          {
                            title: 'ФИО',
                            render: function(text, record, index) {
                              return record?.fio;
                            }
                          },
                          {
                            title: 'Телефон',
                            width: 100,
                            render: function(text, record, index) {
                              return record?.phone;
                            }
                          },
                          {
                            title: "",
                            align: "center",
                            width: 5,
                          },
                        ]}
                        size='small'
                        key="id"
                        pagination={{position: 'topRight', defaultPageSize: 5}}
                      />
                    </>
                  </Col>
                </Row>
              )
            }
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Дата начала</Col>
              <Col span={16}>
                <DatePicker
                  format="DD.MM.YYYY" placeholder="Дата"
                  value={record?.start_dt ? moment(record.start_dt) : null}
                  onChange={(e) => {
                    setRecord({...record, ...{
                        start_dt: e.format("YYYY-MM-DD"),
                        end_dt: e.format("YYYY-MM-DD")
                      }})
                  }}
                  allowClear={false}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Время рассылки</Col>
              <Col span={16}>
                <>
                  <Space direction={"horizontal"} style={{marginBottom: 10}} align={"right"}>
                    <TimePicker onChange={(time, timeString) => { setSelectedTime(time) }}
                                format={'HH:mm'}
                                value={selectedTime}
                                style={{width: 200}} />
                    <Button
                      type="primary"
                      disabled={selectedTime === null}
                      onClick={() => {
                        let newTime = selectedTime.format("HH:mm:ss");
                        let sendTime = record.send_time;
                        if(!sendTime) {
                          sendTime = [newTime];
                        } else {
                          if(sendTime.indexOf(newTime) >= 0) {
                            message.warn("Указанное время уже добавлено");
                            return;
                          } else {
                            sendTime.push(selectedTime.format("HH:mm:ss"));
                          }
                        }
                        setRecord({...record, send_time: sendTime.sort()})
                      }}
                      >
                        Добавить
                    </Button>
                  </Space>
                  <Table
                    dataSource={record?.send_time?.map(el => { return {id: el, title: el} })}
                    columns={[
                      {
                        title: "",
                        align: "center",
                        width: 30,
                      },
                      {
                        title: "",
                        width: 50,
                        align: "center",
                        dataIndex: "id",
                        render: function(text, item, index) {
                          return (
                            <Space direction={"horizontal"}>
                              <Button type="link" icon={<DeleteOutlined/>} onClick={() => {
                                setRecord({...record, send_time: record.send_time.filter(el => el !== item.id)})
                              }}/>
                            </Space>
                          );
                        }
                      },
                      {
                        title: 'Время',
                        render: function(text, record, index) {
                          return record.title;
                        }
                      },
                      {
                        title: "",
                        align: "center",
                        width: 30,
                      },
                    ]}
                    size='small'
                    key="id"
                    pagination={{position: 'topRight', defaultPageSize: 5}}
                  />
                </>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Сообщение</Col>
              <Col span={16}>
                <TextArea
                  rows={5}
                  value={record?.message}
                  onChange={(e) => { setRecord({...record, message: e.target.value} ) }}
                  width={"100%"}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Действия</Col>
              <Col span={16}>
                <>
                  {
                    TgNotificationActionTypes.map(el => (
                      <Checkbox
                        checked={record?.actions?.indexOf(el.id) >= 0}
                        onChange={(e) => {
                          let recordActions = record?.actions || [];
                          if(e.target.checked && recordActions.indexOf(el.id) < 0) {
                            recordActions.push(el.id)
                          } else {
                            recordActions = recordActions.filter(action => action !== el.id)
                          }
                          setRecord({...record, actions: recordActions });
                        }}
                      >{el.title}</Checkbox>
                    ))
                  }
                </>
              </Col>
            </Row>
            {
              pickupRecipients && (
                <DriversPickerDialog
                  onOK={(records) => {
                    setRecipients(prevState => {
                      return [
                        ...prevState,
                        ...records
                      ]
                    })
                    setPickupRecipients(false);
                  }}
                  onCancel={() => setPickupRecipients(false)}
                />
              )
            }
          </>
        )
      }
    </Modal>
  )
}